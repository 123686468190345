import React, { useContext, useState } from 'react'

const ThemeContext = React.createContext()
const ThemeUpdateContext = React.createContext()

// To provide the State.
export function useTheme() {
    return useContext(ThemeContext)
}

// To update  the State.
export function useThemeUpdate() {
    return useContext(ThemeUpdateContext)
}

// To provide the context <> <>
export function ThemeProvider({ children }) {
    const [darkTheme, setDarkTheme] = useState(true)

    function toggleTheme() {
        setDarkTheme((prevDarkTheme) => !prevDarkTheme)
    }

    return (
        <ThemeContext.Provider value={darkTheme}>
            <ThemeUpdateContext.Provider value={toggleTheme}>
                {children}
            </ThemeUpdateContext.Provider>
        </ThemeContext.Provider>
    )
}
