import { createTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            light: '#9a67ea',
            main: '#673ab7',
            dark: '#320b86',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#c7a4ff',
            main: '#9575cd',
            dark: '#65499c',
            contrastText: '#000',
        },
    },
    typography: {
        fontFamily: ['Barlow Condensed', 'sans-serif'].join(','),
        fontSize: 18,
    },
})

export default theme
