import React from 'react'
// import IpfsRouter from 'ipfs-react-router'
/*
import Header from './components/Header'
import Footer from './components/Footer'
import HomePage from './views/HomePage'
import BurnMAM from './views/BurnMAM'
import MintMAM from './views/MintMAM'
import Faucet from './views/Faucet'
import FuseMAM from './views/FuseMAM'
import PageNotFound from './views/PageNotFound'
import TransferMAM from './views/TransferMAM'
import Explore from './views/Explore'
import SupportedNFTs from './views/SupportedNFTs'
import LockMAM from './views/LockMAM'
import Account from './views/Account'
import Information from './views/Information'
import Automation from './views/Automation'
import BurnToPropose from './views/BurnToPropose'
import CreateProposal from './views/CreateProposal'
import { Route, Switch } from 'react-router-dom'*/
import { MuiThemeProvider } from '@material-ui/core/styles'
import lightTheme from './themes/lightTheme'
import darkTheme from './themes/darkTheme'
import { useTheme } from './context/darkMode'
import Card from '@material-ui/core/Card'
import useGoogleAnalytics from './hooks/useGoogleAnalytics'

export default function App() {
    const theme = useTheme()
    useGoogleAnalytics()
    if (process.env.NODE_ENV !== 'development') {
        console.log = () => {}
        console.warn = () => {}
        if (window.location.protocol !== 'https:') {
            //console.log(window.location.protocol);
            window.location.protocol = 'https:'
        }
    }

    return (
        <MuiThemeProvider theme={theme ? darkTheme : lightTheme}>
            <p> Under Maintenance </p>
            <Card>
            { /*  <Header /> 
               
                <Switch>
                    <Route
                        strict
                        exact
                        path="/BurnMAM"
                        render={(props) => <BurnMAM {...props} />}
                    />

                    <Route
                        strict
                        exact
                        path="/TransferMAM"
                        render={(props) => <TransferMAM {...props} />}
                    />

                    <Route
                        strict
                        exact
                        path="/MintMAM"
                        render={(props) => <MintMAM {...props} />}
                    />
                    <Route
                        strict
                        exact
                        path="/FuseMAM"
                        render={(props) => <FuseMAM {...props} />}
                    />

                    <Route
                        strict
                        exact
                        path="/Faucet"
                        render={(props) => <Faucet {...props} />}
                    />
                    <Route
                        strict
                        exact
                        path="/SupportedNFTs"
                        render={(props) => <SupportedNFTs {...props} />}
                    />
                    <Route
                        strict
                        exact
                        path="/LockMAM"
                        render={(props) => <LockMAM {...props} />}
                    />
                    <Route
                        strict
                        exact
                        path="/Information"
                        render={(props) => <Information {...props} />}
                    />
                    <Route
                        strict
                        exact
                        path="/Account"
                        render={(props) => <Account {...props} />}
                    />
                    <Route
                        strict
                        exact
                        path="/Automation"
                        render={(props) => <Automation {...props} />}
                    />

                    <Route
                        strict
                        exact
                        path="/BurnToPropose"
                        render={(props) => <BurnToPropose {...props} />}
                    />
                    <Route
                        strict
                        exact
                        path="/CreateProposal"
                        render={(props) => <CreateProposal {...props} />}
                    />



                    <Route
                        strict
                        exact
                        path="/explore/:urlNetwork/:MAMid"
                        render={(props) => <Explore {...props} />}
                    />
                    <Route
                        strict
                        exact
                        path="/"
                        render={(props) => <HomePage {...props} />}
                    />

                    <Route
                        strict
                        path="*"
                        render={(props) => <PageNotFound {...props} />}
                    />
                </Switch>
                <Footer />*/ }
            </Card>
        </MuiThemeProvider>
    )
}
