import React, { useContext, useState } from 'react'

const AcceptContext = React.createContext()
const AcceptUpdateContext = React.createContext()

// To provide the State.
export function useAccept() {
    return useContext(AcceptContext)
}

// To update  the State.
export function useAcceptUpdate() {
    return useContext(AcceptUpdateContext)
}

// To provide the context <> <>
export function AcceptProvider({ children }) {
    const [riskAccept, setriskAccept] = useState(true)

    function toggleAccept() {
        setriskAccept((prevriskAccept) => !prevriskAccept)
    }

    return (
        <AcceptContext.Provider value={riskAccept}>
            <AcceptUpdateContext.Provider value={toggleAccept}>
                {children}
            </AcceptUpdateContext.Provider>
        </AcceptContext.Provider>
    )
}
