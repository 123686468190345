import { createTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createTheme({
    palette: {
        type: 'dark',
        common: {
            black: '#000',
            white: '#fff',
        },
        primary: {
            light: '#62727b',
            main: '#37474f',
            dark: '#102027',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#819ca9',
            main: '#546e7a',
            dark: '#29434e',
            contrastText: '#ffffff',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
        background: {
            default: '#000',
        },
        text: {
            primary: '#ffffff',
            secondary: '#ffff',
            //   disabled:'#000',
            //  hint:'#000'
        },
    },
    typography: {
        fontFamily: ['Barlow Condensed', 'sans-serif'].join(','),
        fontSize: 18,
    },
})

export default theme
// https://v4.mui.com/customization/color/#picking-colors
/* 

    font-family: 

*/
