import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import App from './App'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { ThemeProvider } from './context/darkMode'
import { AcceptProvider } from './context/acceptMode'

import { HashRouter } from 'react-router-dom'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

function getLibrary(provider) {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
}

ReactDOM.render(
    <React.Fragment>
        <HashRouter>
            <ThemeProvider>
                <AcceptProvider>
                    <CssBaseline />
                    <Web3ReactProvider getLibrary={getLibrary}>
                        <App />
                    </Web3ReactProvider>
                </AcceptProvider>
            </ThemeProvider>
        </HashRouter>
    </React.Fragment>,
    document.querySelector('#root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register()
